import { FieldTimeOutlined } from '@ant-design/icons';
import { Badge, notification } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getRoute } from 'services/router';
import useSound from 'use-sound';
import { useFetch } from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../../../microservices/feeder';
import { getUnresultedMarketsReportsCount } from '../../../../microservices/sports/missing-results-v-2';
import { getRBMLocalStorageKey } from '../../../../services/responsible-bookmaker';
import {
    MISSING_RESULTS_RBM_PRESERVE_KEY,
    MISSING_RESULTS_VOLUME_KEY,
} from '../../../../services/sports/missing-results-v-2';
import { getStoreValue, store } from '../../../../stores/store';

export function SportsbookMissingResultsV2Icon() {
    const [lastMissingResultsCount, setLastMissingResultsCount] = useState<number>();
    const isNotificationEnabled = getStoreValue(store.isNotificationEnabled);
    const [soundVolume] = useLocalStorage(MISSING_RESULTS_VOLUME_KEY, 50);
    const volume = soundVolume ? soundVolume / 100 : 0.5;
    const [playSound] = useSound('/assets/sounds/missing-results.mp3', { volume, interrupt: true });
    const [preservedResponsibleBookmakersId] = useLocalStorage(
        getRBMLocalStorageKey(MISSING_RESULTS_RBM_PRESERVE_KEY),
        [] as number[],
    );

    const { response: currentMissingResultsCount, fetchData: fetchMissingResultsCount } = useFetch(
        () =>
            getUnresultedMarketsReportsCount({
                responsibleBookmakerIds: preservedResponsibleBookmakersId.length
                    ? preservedResponsibleBookmakersId
                    : undefined,
            }),
        {
            errorText: 'Failed to fetch missing results count',
            reactDepsList: [preservedResponsibleBookmakersId],
        },
    );

    useEffect(() => {
        if (currentMissingResultsCount === undefined || lastMissingResultsCount === currentMissingResultsCount) return;

        const shouldNotify =
            isNotificationEnabled && lastMissingResultsCount && currentMissingResultsCount > lastMissingResultsCount;

        if (shouldNotify) {
            notification.warning({
                message: `new missing results detected !`,
                icon: <FieldTimeOutlined />,
            });
            playSound();
        }

        setLastMissingResultsCount(currentMissingResultsCount);
    }, [currentMissingResultsCount]);

    useSocketSubscribeUnsubscribe('public', {
        params: { service: 'sports', channel: 'unresulted-markets-reports-notification' },
    });

    useSocketTopicEvents('unresulted-markets-reports-count-change', () => fetchMissingResultsCount());

    return (
        <Link to={getRoute(ROUTES.sportsbook.missingResultsV2)}>
            <Badge count={lastMissingResultsCount} className="pointer">
                <FieldTimeOutlined className="status-icon" />
            </Badge>
        </Link>
    );
}
