import get from 'lodash/get';
import { ParsedQuery } from 'query-string';

export function getNumberOrNull(fieldName: string, queryParams: ParsedQuery<string>) {
    const urlValue = get(queryParams, fieldName, 'null');
    return urlValue === 'null' ? null : Number(urlValue);
}

export function getArrayOfStrings(fieldName: string, queryParams: ParsedQuery<string>) {
    return (get(queryParams, fieldName, '') as string).split(',').filter(value => value);
}

export function getArrayOfNumbers(fieldName: string, queryParams: ParsedQuery<string>) {
    return getArrayOfStrings(fieldName, queryParams).map(value => Number(value));
}

export function getBoolean(fieldName: string, defaultValue: boolean, queryParams: ParsedQuery<string>) {
    return get(queryParams, fieldName, String(defaultValue)) === 'true';
}

export function getTernary(
    fieldName: string,
    defaultValue: boolean | string,
    queryParams: ParsedQuery<string>,
    ternaryValue: string,
) {
    const urlValue = get(queryParams, fieldName, String(defaultValue));
    return urlValue === ternaryValue ? ternaryValue : urlValue === 'true';
}

export function getString(fieldName: string, defaultValue: string, queryParams: ParsedQuery<string>) {
    const urlValue = get(queryParams, fieldName, defaultValue) as string;
    return ['null', 'undefined'].includes(urlValue) ? null : urlValue;
}

export function getNumber(fieldName: string, defaultValue: number | undefined, queryParams: ParsedQuery<string>) {
    const urlValue = get(queryParams, fieldName, defaultValue);
    return urlValue === undefined ? undefined : Number(urlValue);
}

export function getBase64JsonParams(fieldName: string, queryParams: ParsedQuery<string>): Record<number, number[]> {
    const urlValue = get(queryParams, fieldName, '') as string;
    try {
        return urlValue ? JSON.parse(window.atob(urlValue)) : {};
    } catch (error) {
        return {};
    }
}
