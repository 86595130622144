import React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { initHistory } from 'services/browser';
import { Bootstrap } from './Bootstrap';
import { Router } from 'react-router-dom';
import 'services/dayjs';

serviceWorker.unregister();

const history = initHistory();
const container = document.getElementById('root');
const version = process.env.REACT_APP_VERSION || 'development';
const root = ReactDOM.createRoot(container as Element);

root.render(
    <Router history={history}>
        <Bootstrap />
        <div id="version">{version}</div>
    </Router>,
);
