import { Bookmaker } from 'types/auth-bo';
import type { DatabaseSequence, MarketStatus } from '@staycool/sports-types';
import { DatabaseBet, DatabaseTicket } from '@staycool/bets-types';
import type { ComboCardStatistics } from '@staycool/bets-types/combo-card';

export interface Bet extends DatabaseBet {
    total_odds?: number;
    selections?: Selection[];
}

export enum TicketTypeEnum {
    single = 'single',
    combo = 'combo',
    system = 'system',
    teaser = 'teaser',
    parlayCard = 'parlayCard',
    comboCard = 'comboCard',
}

export enum BetStatusEnum {
    WON = 'WON',
    LOST = 'LOST',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
    MANUAL_ACCEPTANCE = 'MANUAL_ACCEPTANCE',
    MANUAL_ACCEPTANCE_DENIED = 'MANUAL_ACCEPTANCE_DENIED',
    PUSHED = 'PUSHED',
}

export enum ProductTypeEnum {
    LIVE = 'LIVE',
    PREMATCH = 'PREMATCH',
    MIXED = 'MIXED',
}

export enum SelectionStatusesEnum {
    MANUAL_ACCEPTANCE = 'MANUAL_ACCEPTANCE',
    MANUAL_ACCEPTANCE_DENIED = 'MANUAL_ACCEPTANCE_DENIED',
    CANCELLED = 'CANCELLED',
    WON = 'WON',
    LOST = 'LOST',
    PENDING = 'PENDING',
    MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
}

export type SelectionStatus =
    | SelectionStatusesEnum.CANCELLED
    | SelectionStatusesEnum.LOST
    | SelectionStatusesEnum.MANUALLY_CANCELLED
    | SelectionStatusesEnum.PENDING
    | SelectionStatusesEnum.MANUAL_ACCEPTANCE
    | SelectionStatusesEnum.WON
    | SelectionStatusesEnum.MANUAL_ACCEPTANCE_DENIED;

export type Currency = DatabaseTicket['currency'];

export type OutcomeTradingPosition = 'PR' | 'Real' | '100%' | null;

export type OddsFormat = 'DECIMAL' | 'AMERICAN';

type RetailType = 'kiosk' | 'otc';
export interface IExtraInfo {
    campaignId?: number;
    freeBet?: UUID;
    multiplierBet?: UUID;
    riskFreeBet?: UUID;
    retail?: RetailType;
    loyaltyId?: string;
    shopId?: string;
    patronName?: string;
}

export type UUID = string;

export interface BetInfo {
    match_id: number;
    view_type?: string;
    match_name: string;
    away_team: string;
    home_team: string;
    team_names?: string[];
    match_start: Date;
    sport_name: string;
    region_name: string;
    league_name: string;
    league_id?: number;
    market_name: string;
    market_id?: number;
    market_line?: number;
    market_status?: MarketStatus;
    market_type_id?: number;
    outcome_name: string;
    outcome_id?: number;
    status: SelectionStatus;
    trading_position: OutcomeTradingPosition;
    our_betting_time_odds: number;
    our_closing_odds?: number;
    pinnacle_betting_time_odds?: number;
    pinnacle_closing_odds?: number;
    riskBc?: number;
    bookmakers: Bookmaker[];
    internal_comment: string;
    cancel_reason?: string;
    riskPercentage?: number | null;
    product: ProductTypeEnum;
    cashout_at_live?: boolean;
    cashout_status?: string;
    cashout_odds?: number;
    odds: string | number;
    odds_format?: OddsFormat;
    display_odds?: string;
    market_sequence: DatabaseSequence | null;
    market_type_group?: string;
    resulted_outcome_processed?: Date;
    market_group_type?: string;
}

export interface ComboCardStatisticsResponse {
    result: ComboCardStatistics[];
    count: number;
}
