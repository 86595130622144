import React, { useState } from 'react';
import { Button, Drawer, Space } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { logout } from 'services/auth';
import Wrapper from './styles';
import { isMobile } from 'services/browser';
import { HeaderStatus } from '../status/HeaderStatus';
import { HeaderUserSettingsVolume } from './volume/HeaderUserSettingsVolume';
import { ALARM_VOLUME_KEY } from 'services/alarm';
import { HeaderUserSettingsDisplayNotActive } from './display-not-active/HeaderUserSettingsDisplayNotActive';
import { HeaderUserSettingsNotification } from './notification/HeaderUserSettingsNotification';
import { ROLES, useKeycloak } from 'services/keycloak';
import { HeaderUserSettingsOddsFormat } from './odds-format/HeaderUserSettingsOddsFormat';
import { HeaderUserSettingsTicketListSettings } from './ticket-list-settings/HeaderUserSettingsTicketListSettings';
import { isFeatureAvailable } from 'services/features';
import { MISSING_RESULTS_VOLUME_KEY } from '../../../services/sports/missing-results-v-2';

const { SPORTS } = ROLES.SPORTS;

export function HeaderUserSettings() {
    const keycloak = useKeycloak();

    const isAvailableHeader = keycloak.hasAccessForPermissions([SPORTS]);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    return (
        <Wrapper>
            <Space className="user-settings-btn" size="small">
                {!isMobile() && isAvailableHeader && <HeaderStatus />}
                {/* eslint-disable-next-line rulesdir/forbid-jsx-component */}
                <Button type="text" onClick={() => setIsDrawerVisible(true)} icon={<UserOutlined />} />
            </Space>
            <Drawer
                title={
                    <>
                        <UserOutlined /> User Settings
                    </>
                }
                placement="right"
                closable={true}
                onClose={() => setIsDrawerVisible(false)}
                open={isDrawerVisible}
                contentWrapperStyle={{ height: 'auto' }}
            >
                <Space direction="vertical" size="large">
                    {/* eslint-disable-next-line rulesdir/forbid-jsx-component */}
                    <Button onClick={() => logout()} icon={<LogoutOutlined />}>
                        Logout
                    </Button>
                    {isFeatureAvailable('manualAcceptanceAggregate') && (
                        <HeaderUserSettingsVolume label="MA Sound Volume" localStorageKey="settings-ma-sound-volume" />
                    )}
                    {isFeatureAvailable('alarms') && (
                        <HeaderUserSettingsVolume label="Alarm Sound Volume" localStorageKey={ALARM_VOLUME_KEY} />
                    )}
                    {isFeatureAvailable('missingResultsV2') && (
                        <HeaderUserSettingsVolume
                            label="Missing Results Sound Volume"
                            localStorageKey={MISSING_RESULTS_VOLUME_KEY}
                        />
                    )}
                    {(isFeatureAvailable('alarms') || isFeatureAvailable('manualAcceptanceAggregate')) && (
                        <HeaderUserSettingsNotification />
                    )}
                    <HeaderUserSettingsDisplayNotActive />
                    <HeaderUserSettingsOddsFormat />
                    <HeaderUserSettingsTicketListSettings />
                </Space>
            </Drawer>
        </Wrapper>
    );
}
