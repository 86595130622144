import { Feature } from '../feature-config';
import { ROUTES } from '../routes';
import { isFeatureAvailable } from './features';
import { ROLES } from './keycloak';
import { getNewRoute, getRoute } from './router';
import { isSportsbookCore } from './util';

const {
    COMBO_CARD,
    SPORTS,
    TEASERS_CONFIGURATION,
    LIMITS_CONFIGURATION,
    FEED_USERS,
    SMART_RESULTING_CONFIGURATION,
    PARLAY_CARD_CONFIGURATION,
} = ROLES.SPORTS;
const { BO_INQUIRY, OBSERVER, SETTINGS, REPORTS } = ROLES.RETAIL_PROXY;
const { CMS_WRITE } = ROLES.CMS;
const { DIGITAL_BOARD } = ROLES.DIGITAL_BOARD;
const { SEGMENTS_READ } = ROLES.SEGMENTS;
const { RECOMMENDATIONS_WRITE } = ROLES.RECOMMENDATIONS;
const { EMAIL_READ } = ROLES.EMAIL;
const { USER_MANAGE } = ROLES.AUTH_BO;
const { SMS_WRITE, SMS_READ, SMS_ADMIN } = ROLES.SMS;

export const getSportsbookViews = () => {
    const features: (ViewWithFeature | false)[] = [
        { title: 'Sports Tree', to: getRoute(ROUTES.sportsbook.categories) },
        { title: 'Net Risk', to: getRoute(ROUTES.sportsbook.netRisk), feature: 'netrisk' },
        { title: 'Trading', to: getRoute(ROUTES.sportsbook.trading), feature: 'trading' },
        {
            title: 'Ticket list',
            to: getRoute(ROUTES.sportsbook.ticketList),
            feature: 'ticketList',
            permissions: [ROLES.TICKET_LIST.READ],
        },
        {
            title: 'Tickets Freeze',
            to: getRoute(ROUTES.sportsbook.ticketsFreeze),
            feature: 'ticketFreeze',
            permissions: [ROLES.SBGATE.MAINTENANCE],
        },
        { title: 'Alarms', to: getRoute(ROUTES.sportsbook.alarms), feature: 'alarms' },
        {
            title: 'Manual Acceptance',
            to: getRoute(ROUTES.sportsbook.manualAcceptanceAggregate),
            feature: 'manualAcceptanceAggregate',
        },
        { title: 'Missing Results', to: getRoute(ROUTES.sportsbook.missingResults), feature: 'missingResults' },
        { title: 'Missing Results V2', to: getRoute(ROUTES.sportsbook.missingResultsV2), feature: 'missingResultsV2' },
        {
            title: 'Unresulted Tickets',
            to: getRoute(ROUTES.sportsbook.tickets.pendingUnresulted),
            feature: 'tickets',
        },
        { title: 'Overall Risk', to: getRoute(ROUTES.sportsbook.overallRisk), feature: 'overallRisk' },
        { title: 'Overall Risk V2', to: getRoute(ROUTES.sportsbook.overallRiskV2), feature: 'overallRiskV2' },
        { title: 'Statistics', to: getRoute(ROUTES.sportsbook.statistics) },
        { title: 'Turnover', to: getRoute(ROUTES.sportsbook.turnover), feature: 'turnover' },
        { title: 'Winlist', to: getRoute(ROUTES.sportsbook.winlist), feature: 'winlist' },
        {
            title: 'Ref feed',
            to: getRoute(ROUTES.sportsbook.refFeed),
            children: [
                { title: 'Ref Feed Mapping', to: getRoute(ROUTES.sportsbook.refFeed), feature: 'referenceFeed' },
                { title: 'Ref Feed Alerts', to: getRoute(ROUTES.sportsbook.refFeedAlerts), feature: 'referenceFeed' },
            ],
            feature: 'referenceFeed',
        },
        {
            title: 'Betradar',
            to: getRoute(ROUTES.sportsbook.betradarMapping),
            children: [
                {
                    title: 'Betradar Market Types',
                    to: getRoute(ROUTES.sportsbook.betradarMarketTypes),
                    feature: 'betradar',
                },
                { title: 'Betradar Mapping', to: getRoute(ROUTES.sportsbook.betradarMapping), feature: 'betradar' },
                { title: 'Betradar Alerts', to: getRoute(ROUTES.sportsbook.betradarAlerts), feature: 'betradar' },
            ],
            feature: 'betradar',
        },
        {
            title: 'Openbet',
            to: getRoute(ROUTES.sportsbook.openbetMapping),
            children: [
                { title: 'Openbet Mapping', to: getRoute(ROUTES.sportsbook.openbetMapping), feature: 'openbet' },
            ],
            feature: 'openbet',
        },
        { title: 'W-2G events', to: getRoute(ROUTES.sportsbook.w2G), feature: 'w2g' },
        { title: 'SimpleBet Mapping', to: getRoute(ROUTES.sportsbook.simpleBet), feature: 'simpleBet' },
        {
            title: 'BetGenius',
            to: getRoute(ROUTES.sportsbook.betgeniusMapping),
            children: [{ title: 'BetGenius Mapping', to: getRoute(ROUTES.sportsbook.betgeniusMapping) }],
            feature: 'core',
        },
        {
            title: 'Small Reports',
            to: getRoute(ROUTES.sportsbook.smallReports),
            feature: 'smallReports',
        },
    ];
    return features
        .filter((x): x is ViewWithFeature => Boolean(x))
        .map(view => ({ ...view, permissions: view.permissions || [SPORTS] }));
};

export const getSportsbookSettingsViews = () =>
    [
        ...[
            { title: 'Sports', to: getRoute(ROUTES.sportsbook.sports), feature: 'core' },
            { title: 'Teams', to: getRoute(ROUTES.sportsbook.teams) },
            { title: 'Players', to: getRoute(ROUTES.sportsbook.players) },
            { title: 'Regions', to: getRoute(ROUTES.sportsbook.regions), feature: 'core' },
            { title: 'Market types', to: getRoute(ROUTES.sportsbook.marketType.list) },
            { title: 'Market types groups', to: getRoute(ROUTES.sportsbook.marketTypeGroups) },
            { title: 'Market types templates', to: getRoute(ROUTES.sportsbook.marketTypesTemplates), feature: 'core' },
            { title: 'Risk groups', to: getRoute(ROUTES.sportsbook.riskGroups.list), feature: 'riskGroups' },
            { title: 'Hot Events', to: getRoute(ROUTES.sportsbook.hotEvents), feature: 'hotEvents' },
            { title: 'Sports order', to: getRoute(ROUTES.sportsbook.sportsOrder), feature: 'sportsOrder' },
            { title: 'Odds Ladders', to: getRoute(ROUTES.sportsbook.odds), feature: 'oddsLadders' },
            { title: 'Add new categories', to: `${getRoute(ROUTES.sportsbook.categories)}-new`, feature: 'core' },
            { title: 'Blocked countries', to: getRoute(ROUTES.sportsbook.blockedCountries) },
        ]
            .filter(Boolean)
            .map(view => ({ ...view, permissions: [SPORTS] })),
        {
            title: 'Teasers',
            to: getRoute(ROUTES.sportsbook.teaser),
            feature: 'teasers',
            permissions: [TEASERS_CONFIGURATION],
        },
        {
            title: 'Parlay Cards',
            to: getRoute(ROUTES.sportsbook.parlayCard),
            feature: 'parlayCard',
            permissions: [PARLAY_CARD_CONFIGURATION],
        },
        {
            title: 'Combo Cards',
            to: getRoute(ROUTES.sportsbook.comboCard),
            feature: 'comboCard',
            permissions: [COMBO_CARD],
        },
        {
            title: 'Clients',
            to: getRoute(ROUTES.sportsbook.clients),
            feature: 'clientConfigs',
            permissions: [ROLES.CLIENTS.CLIENTS_READ],
        },
        {
            title: 'Limits configuration',
            to: getRoute(ROUTES.sportsbook.limitConfiguration),
            feature: 'limitsConfiguration',
            permissions: [LIMITS_CONFIGURATION],
        },
        {
            title: 'Smart resulting',
            to: getRoute(ROUTES.sportsbook.smartResulting),
            feature: 'smartResulting',
            permissions: [SMART_RESULTING_CONFIGURATION],
        },
        {
            title: 'Feed Users',
            to: getRoute(ROUTES.sportsbook.feedUsers),
            feature: 'feedUsers',
            permissions: [FEED_USERS],
        },
        {
            title: 'Same match combo market type set',
            to: getRoute(ROUTES.sportsbook.sameMatchCombo),
            feature: 'sameMatchCombo',
        },
        {
            title: 'Donbest mapping',
            to: getRoute(ROUTES.sportsbook.donbestMapping),
            feature: 'donbest',
            permissions: [ROLES.DONBEST.READ],
        },
    ] as ViewWithFeature[];

export const getDevToolsViews = () =>
    [
        ...(isSportsbookCore()
            ? []
            : [
                  {
                      title: 'Core Feed Pick Columns',
                      to: getRoute(ROUTES.sportsbook.tools.pick),
                      permissions: [ROLES.CLIENTS.CLIENTS_WRITE],
                  },
              ]),
        {
            title: 'Fixture Provider',
            to: getRoute(ROUTES.sportsbook.tools.fixtureProvider),
            permissions: [ROLES.SPORTS.SPORTS],
        },
    ] as ViewWithFeature[];

export const getRacebookViews = () =>
    [
        {
            title: 'Races',
            to: getRoute(ROUTES.racebook.races),
            feature: 'racebookRaces',
        },
        {
            title: 'Tickets',
            to: getRoute(ROUTES.racebook.tickets),
            children: [
                { title: 'All tickets', to: getRoute(ROUTES.racebook.tickets), feature: 'racebookRaces' },
                {
                    title: 'Resettled tickets',
                    to: `${getRoute(ROUTES.racebook.tickets)}/resettled`,
                    feature: 'racebookRaces',
                },
            ],
            feature: 'racebookRaces',
        },
        {
            title: 'Bet Types',
            to: getRoute(ROUTES.racebook.betTypes),
            feature: 'racebookRaces',
        },
    ] as ViewWithFeature[];

export const getUsersViews = () =>
    [
        { title: 'User List', to: getRoute(ROUTES.users), feature: 'users' },
        {
            title: 'Create New User',
            to: getRoute(ROUTES.users.create),
            feature: 'createNewUser',
            permissions: [ROLES.USERS.CREATE],
        },
        {
            title: 'Refer a friend',
            to: getRoute(ROUTES.users.raf),
            feature: 'referAFriend',
            permissions: [ROLES.REFER_A_FRIEND.VIEW],
        },
        {
            title: 'Refer a friend settings',
            to: getNewRoute('refer-a-friend'),
            feature: 'referAFriend',
            permissions: [ROLES.REFER_A_FRIEND.VIEW],
        },
        {
            title: 'User Login History',
            to: getRoute(ROUTES.users.loginHistory),
            feature: 'loginHistory',
            permissions: [ROLES.USERS.USER_LOGIN_HISTORY_VIEW],
        },
        {
            title: 'Alias helper',
            to: getRoute(ROUTES.users.alias),
            feature: 'userAlias',
            permissions: [ROLES.USERS.VIEW_MINIMAL],
        },
    ] as ViewWithFeature[];

export const getSecurityViews = () =>
    [
        {
            title: 'Account reopening request',
            to: getRoute(ROUTES.security.closureAppeals),
            permissions: [ROLES.USERS.REOPEN_REQUEST_VIEW],
        },
        {
            title: 'AML',
            to: getRoute(ROUTES.security.amlSettings),
            permissions: [ROLES.AML.READ],
        },
        {
            title: 'KYC',
            to: getRoute(ROUTES.security.kyc),
            feature: 'kyc',
            permissions: [ROLES.KYC.READ],
            children: [
                {
                    title: 'Documents',
                    to: getRoute(ROUTES.security.kyc.list),
                },
                {
                    title: 'Upload limits',
                    to: getRoute(ROUTES.security.kyc.uploadLimits),
                },
                {
                    title: 'Settings',
                    to: getRoute(ROUTES.security.kyc.settings),
                },
            ],
        },
        {
            title: 'Registry check',
            to: getRoute(ROUTES.security.registryCheck),
            feature: 'registryCheck',
        },
        {
            title: 'Responsible gaming',
            to: getRoute(ROUTES.security.responsibleGaming),
            feature: 'responsibleGaming',
            permissions: [ROLES.RESPONSIBLE_GAMING.READ],
            children: [
                {
                    title: 'Dashboard',
                    to: getRoute(ROUTES.security.responsibleGaming.dashboard),
                },
                {
                    title: 'Limit change requests',
                    to: getRoute(ROUTES.security.responsibleGaming.limitChangeRequests),
                },
                {
                    title: 'Search',
                    to: getRoute(ROUTES.security.responsibleGaming.search),
                },
            ],
        },
        {
            title: 'Security matches',
            to: getRoute(ROUTES.security.match),
            feature: 'securityMatches',
            permissions: [ROLES.SECURITY_MATCH.READ],
        },
        {
            title: 'Integrity',
            to: getRoute(ROUTES.security.integrity),
            permissions: [ROLES.INTEGRITY.READ],
        },
        {
            title: 'Other',
            children: [
                {
                    title: '2FA SMS settings',
                    to: getRoute(ROUTES.security.twoFaSmsSettings),
                    feature: 'twoFactorAuth',
                },
                {
                    title: 'Politically exposed users',
                    to: getRoute(ROUTES.security.pep),
                    permissions: [ROLES.PEP.VIEW],
                    feature: 'pep',
                },
            ],
        },
    ] as ViewWithFeature[];

export const getPaymentsViews = () =>
    [
        {
            title: 'Autopayout',
            to: getRoute(ROUTES.payments.autopayout),
            permissions: [ROLES.PAYMENTS2.READ],
            feature: 'autoPayout',
        },
        {
            title: 'Failed deposits',
            to: getRoute(ROUTES.payments.deposits),
            permissions: [ROLES.PAYMENTS2.USER],
            feature: 'payments',
        },
        {
            title: 'Export Providers',
            to: getRoute(ROUTES.payments.exportProviders),
            permissions: [ROLES.PAYMENTS2.EXPORT],
            feature: 'paymentsExportProviders',
        },
        {
            title: 'Providers',
            to: getRoute(ROUTES.payments.providers),
            children: [
                {
                    title: 'Settings',
                    to: getRoute(ROUTES.payments.providers.settings),
                    feature: 'payments',
                },
                {
                    title: 'Balances',
                    to: getRoute(ROUTES.payments.providers.balances),
                    permissions: [ROLES.PSP_BALANCES.READ],
                    feature: 'pspBalances',
                },
                {
                    title: 'Transactions',
                    to: getRoute(ROUTES.payments.providers.transactions),
                    feature: 'payments',
                },
            ],
            permissions: [ROLES.PAYMENTS2.READ],
            feature: 'payments',
        },
        {
            title: 'Payment packages',
            to: getRoute(ROUTES.payments.paymentPackages),
            permissions: [ROLES.PAYMENT_PACKAGES.READ],
            feature: 'paymentPackages',
        },
        {
            title: 'Shop',
            to: getRoute(ROUTES.payments.shop),
            permissions: [ROLES.PAYMENT_PACKAGES.READ],
            feature: 'paymentPackages',
        },
        {
            title: 'Reconciliation',
            to: getRoute(ROUTES.payments.reconciliation),
            children: [
                {
                    title: 'Accounting',
                    to: getRoute(ROUTES.payments.reconciliation.accounting),
                    feature: 'reconciliation',
                },
                {
                    title: 'Operations',
                    to: getRoute(ROUTES.payments.reconciliation.operations),
                    feature: 'reconciliation',
                },
            ],
            permissions: [ROLES.RECONCILIATION.READ],
            feature: 'reconciliation',
        },
        {
            title: 'Routing',
            to: getRoute(ROUTES.payments.routing),
            permissions: [ROLES.ROUTING.READ],
            feature: 'paymentsProviderRouting',
        },
        {
            title: 'Virtual currencies',
            to: getRoute(ROUTES.payments.virtualCurrencies),
            permissions: [ROLES.WALLET.VIRTUAL_CURRENCIES_READ],
            feature: 'virtualCurrencies',
        },
        {
            title: 'Transactions',
            to: getRoute(ROUTES.payments.transactions),
            feature: 'payments',
        },
        {
            title: 'Withdrawal requests',
            to: getRoute(ROUTES.payments.withdrawals),
            permissions: [ROLES.PAYMENTS2.TRANSACTIONS_READ],
            feature: 'withdrawals',
        },
        {
            title: 'Other',
            to: getRoute(ROUTES.payments.other),
            permissions: [ROLES.PAYMENTS2.READ],
            feature: 'paymentsOthers',
        },
    ] as ViewWithFeature[];

export const getCasinoViews = (isSimGamingClient: boolean) =>
    [
        {
            title: 'Providers',
            to: getRoute(ROUTES.casino.providers),
            permissions: [ROLES.CASINO.GAMES_READ],
            feature: 'casino',
        },
        {
            title: 'Casino Blocking',
            to: getRoute(ROUTES.casino.locationBlocking),
        },
        {
            title: 'Bonuses',
            to: getRoute(ROUTES.casino.bonuses.providers),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Games compliance',
            to: getRoute(ROUTES.casino.games.compliance),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Games Table',
            to: getRoute(ROUTES.casino.games.table),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Game rounds',
            to: getRoute(ROUTES.casino.games.rounds),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Thrilltech rounds',
            to: getRoute(ROUTES.casino.games.thrilltech),
            permissions: [ROLES.CASINO.GAMES_READ],
            feature: 'THRILLTECH_JACKPOT_MENU_LINK',
        },
        {
            title: isSimGamingClient ? 'Leaderboards' : 'Races',
            to: getRoute(ROUTES.casino.races),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Available games',
            to: getRoute(ROUTES.casino.games.available),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Jackpots',
            to: getRoute(ROUTES.casino.jackpots),
            permissions: [ROLES.CASINO.GAMES_READ],
            feature: 'JACKPOTS_MENU_LINK',
        },
        {
            title: 'Licences',
            to: getRoute(ROUTES.casino.licences),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Virtual sports',
            to: getRoute(ROUTES.casino.virtualSports),
            permissions: [ROLES.CASINO.GAMES_READ],
            feature: 'VIRTUAL_SPORTS_MENU_LINK',
        },
        {
            title: 'Maintenance',
            to: getRoute(ROUTES.casino.maintenance),
            permissions: [ROLES.HIGHLIGHT_GAMES.MAINTENANCE_WRITE],
        },
        {
            title: 'Profanities',
            to: getRoute(ROUTES.casino.profanities),
            permissions: [ROLES.PROFANITIES.PROFANITIES_WRITE],
        },
        {
            title: 'Payback',
            to: getRoute(ROUTES.casino.payback),
            permissions: [ROLES.PAYBACK.PAYBACK_READ],
        },
        {
            title: 'Lobby',
            to: getRoute(ROUTES.casino.lobby),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Other',
            to: getRoute(ROUTES.casino.other),
            permissions: [ROLES.CASINO.GAMES_READ],
        },
        {
            title: 'Segments',
            to: getRoute(ROUTES.casino.segments),
            permissions: [ROLES['CASINO-SEGMENTS'].CASINO_SEGMENTS_READ],
        },
    ] as ViewWithFeature[];

export const getPokerViews = () =>
    [
        {
            title: 'Manual corrections',
            to: getRoute(ROUTES.poker.manualCorrections),
            permissions: [ROLES.POKER_PLAYTECH_GAMESLINK.READ],
        },
        {
            title: 'EUR corrections',
            to: getRoute(ROUTES.poker.customCorrections),
            permissions: [ROLES.POKER_PLAYTECH_GAMESLINK.READ],
        },
        {
            title: 'Tournaments players',
            to: getRoute(ROUTES.poker.tournamentsPlayers),
            permissions: [ROLES.POKER_PLAYTECH_GAMESLINK.READ],
        },
        {
            title: 'Blocked countries',
            to: getRoute(ROUTES.poker.blockedCountries),
            permissions: [ROLES.POKER_PLAYTECH_GAMESLINK.READ],
        },
        {
            title: 'Maintenance',
            to: getRoute(ROUTES.poker.maintenance),
            permissions: [ROLES.POKER_PLAYTECH_GAMESLINK.READ],
        },
        {
            title: 'Coolbet open',
            to: getRoute(ROUTES.poker.coolbetOpen),
        },
        {
            title: 'Rake',
            to: getRoute(ROUTES.poker.rake),
        },
        {
            title: 'Whitelisted users',
            to: getRoute(ROUTES.poker.whitelistedUsers),
        },
    ] as ViewWithFeature[];

export const getRetailViews = () =>
    [
        {
            title: 'Bet shops',
            to: getRoute(ROUTES.retail.betShop.list),
            feature: 'retail',
            permissions: [OBSERVER],
        },
        {
            title: 'Kiosk/OTC',
            to: getRoute(ROUTES.retail.terminals.list),
            feature: 'retail',
            permissions: [SETTINGS],
        },
        {
            title: 'Reports',
            to: getRoute(ROUTES.retail.reports),
            feature: 'retail',
            permissions: [REPORTS],
        },
        {
            title: 'Inquiry',
            to: getRoute(ROUTES.retail.inquiry),
            feature: 'retail',
            permissions: [BO_INQUIRY],
        },
        {
            title: 'Notifications',
            to: getRoute(ROUTES.retail.notifications),
            feature: 'retail',
            permissions: [OBSERVER],
        },
        {
            title: 'Settings',
            to: getRoute(ROUTES.retail.settings),
            feature: 'retail',
            permissions: [SETTINGS],
        },
        ...(isFeatureAvailable('USE_NEW_OTC_AUTH')
            ? [
                  {
                      title: 'OTC Permission Groups',
                      to: getRoute(ROUTES.retail.permissions),
                      feature: 'retail',
                      permissions: [OBSERVER],
                  },
                  {
                      title: 'OTC Users',
                      to: getRoute(ROUTES.retail.otcUsers),
                      feature: 'retail',
                      permissions: [OBSERVER],
                  },
              ]
            : [
                  {
                      title: 'Account Management',
                      to: getRoute(ROUTES.retail.users.list),
                      feature: 'retail',
                      permissions: [OBSERVER],
                  },
              ]),
    ] as ViewWithFeature[];

export const getFeatureViews = () =>
    [
        {
            title: 'Search',
            to: getRoute(ROUTES.features),
            permissions: [ROLES.FEATURES.READ],
        },
    ] as ViewWithFeature[];

export const getAdministrationViews = () =>
    [
        {
            title: 'Backoffice users',
            to: getRoute(ROUTES.users.customKeycloak),
            feature: 'users',
            permissions: [USER_MANAGE],
        },
    ] as ViewWithFeature[];

export const getCmsViews = () =>
    [
        {
            title: 'General Settings',
            to: getRoute(ROUTES.cms.generalSettings),
            feature: 'cmsGeneralSettings',
        },
        {
            title: 'Articles',
            to: getRoute(ROUTES.cms.articles),
            feature: 'articles_v2',
            permissions: [],
        },
        {
            title: 'Blog',
            to: getRoute(ROUTES.cms.blog),
            feature: 'blog',
            permissions: [],
        },
        {
            title: 'Banner Messages',
            to: getRoute(ROUTES.cms.bannerMessages),
            feature: 'bannerMessages',
            permissions: [],
        },
        {
            title: 'Email',
            to: getRoute(ROUTES.cms.email.content),
            feature: 'email',
            permissions: [EMAIL_READ],
        },
        {
            title: 'Match promotion',
            to: getRoute(ROUTES.cms.matchPromotion),
            feature: 'matchPromotion',
        },
        {
            title: 'Media gallery',
            to: getRoute(ROUTES.cms.mediaGallery),
            feature: 'mediaGallery',
        },
        {
            title: 'Quiz',
            to: getRoute(ROUTES.cms.quiz),
            feature: 'quiz',
            permissions: [],
        },
        {
            title: 'Retail Content',
            to: getRoute(ROUTES.cms.retail.list),
            feature: 'retail',
            permissions: [CMS_WRITE, DIGITAL_BOARD],
        },
        {
            title: 'Sportsbook Translations',
            to: getRoute(ROUTES.cms.sportsbookTranslations),
            feature: 'translations',
            permissions: [SPORTS],
        },
        {
            title: 'SMS',
            to: getRoute(ROUTES.cms.sms),
            permissions: [SMS_READ, SMS_WRITE, SMS_ADMIN],
        },
        {
            title: 'Snippets',
            to: getRoute(ROUTES.cms.snippets),
            feature: 'snippets',
            permissions: [],
        },
        {
            title: 'Terms and Conditions',
            to: getRoute(ROUTES.cms.termsAndConditions),
            feature: 'termsAndConditions',
            permissions: [],
        },
        {
            title: 'Translations',
            to: getRoute(ROUTES.cms.translations),
            feature: 'translations',
            permissions: [],
        },
    ].filter(Boolean) as ViewWithFeature[];

export const getMarketingViews = () =>
    [
        {
            title: 'Bonuses',
            to: getRoute(ROUTES.marketing.bonuses),
            feature: 'bonuses',
        },
        {
            title: 'Campaigns',
            to: getNewRoute('sb-campaigns'),
            feature: 'campaigns',
        },
        {
            title: 'Onsite Message',
            to: getRoute(ROUTES.marketing.crm),
            feature: 'crm',
        },
        {
            title: 'Campaigns (v2)',
            to: getRoute(ROUTES.marketing.campaigns),
            feature: 'campaigns_v2',
        },
        {
            title: 'Loyalty',
            to: getRoute(ROUTES.marketing.loyalty),
            feature: 'loyalty',
        },
        {
            title: 'Promotions',
            to: getRoute(ROUTES.marketing.promotions.order),
            feature: 'promotions',
        },
        {
            title: 'Match of the day',
            to: getRoute(ROUTES.marketing.motd),
            feature: 'matchOfTheDay',
        },
        {
            title: 'Scratchcards',
            to: getRoute(ROUTES.marketing.scratchcards),
            feature: 'scratchcards',
        },
        {
            title: 'Segments',
            to: getRoute(ROUTES.marketing.segments),
            feature: 'segments',
            permissions: [SEGMENTS_READ],
        },
        {
            title: 'Contests',
            to: getRoute(ROUTES.marketing.contest),
            feature: 'contests',
        },
        {
            title: 'Tournaments',
            to: getRoute(ROUTES.marketing.tournaments),
            feature: 'tournaments',
        },
        {
            title: 'Upsell',
            to: getRoute(ROUTES.marketing.upsell),
            permissions: [RECOMMENDATIONS_WRITE],
            feature: 'upsell',
        },
        {
            title: 'Event Based Rewards',
            to: getRoute(ROUTES.marketing.eventBasedRewards),
            feature: 'eventBasedRewards',
        },
    ].filter(Boolean) as ViewWithFeature[];

export const getDataWarehouseViews = () =>
    [
        {
            title: 'Status',
            feature: 'warehouse',
            to: getRoute(ROUTES.warehouse.status),
        },
        {
            title: 'Extract',
            feature: 'warehouse',
            to: getRoute(ROUTES.warehouse.extract),
        },
        {
            title: 'Delivery',
            feature: 'warehouse',
            to: getRoute(ROUTES.warehouse.delivery),
        },
        {
            title: 'Extract Errors',
            feature: 'warehouse',
            to: getRoute(ROUTES.warehouse.extract.errors),
        },
        {
            title: 'Delivery errors',
            feature: 'warehouse',
            to: getRoute(ROUTES.warehouse.delivery.errors),
        },
    ] as ViewWithFeature[];

export interface ViewWithFeature {
    title: string;
    to: string;
    feature?: Feature;
    children?: ViewWithFeature[];
    permissions?: string[];
}

export interface MenuItem {
    views: ViewWithFeature[];
    icon: any;
    displayName: string;
    feature?: Feature;
}
